import { motion } from 'motion/react'
import { useRef } from 'react'

import { ClientOnly } from '~/components/ClientOnly'

/**
 * A panel that can be collapsed and expanded given an isOpen prop. The main
 * purpose of this is to handle overflows with animating height. It's a problem
 * I've had for awhile and its best to just throw it into a reusable component.
 *
 * ```tsx
 * // Example:
 *
 * const [isOpen, setIsOpen] = useState(false);
 *
 * <button onClick={() => setIsOpen(!isOpen)}>
 *   {isOpen ? 'Close' : 'Open'}
 * </button>
 *
 * <CollapsiblePanel isOpen={isOpen}>
 *   lorem ipsum
 * </CollapsiblePanel>
 * ```
 */
const CollapsiblePanel = (props: { children: React.ReactNode; isOpen: boolean }) => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <ClientOnly>
      <motion.div
        ref={ref}
        animate={{ height: props.isOpen ? 'auto' : 0 }}
        initial={{ height: props.isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.2 }}
        onAnimationStart={() => {
          if (ref.current) {
            ref.current.style.overflow = 'hidden'
          }
        }}
        onAnimationComplete={(animation: { height: number }) => {
          if (ref.current && animation.height !== 0) {
            ref.current.style.overflow = ''
          }
        }}
      >
        {props.children}
      </motion.div>
    </ClientOnly>
  )
}

export default CollapsiblePanel
